<template>
  <div class="tabs-container" :class="[customClass]">
    <div
      class="tab"
      v-for="tab in data"
      :class="{'tab__active': tab.isActive}"
      :key="tab.status_name"
      @click="$emit('onClick', tab)">
      <div class="tab--counter text-12-400">
        {{tab.count}}
      </div>
      <div class="text-14-700">
        {{tab.statusName || tab.status_name}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTabs',
  props: [
    'data',
    'customClass',
  ],
}
</script>

<style lang="scss" scoped>
  .tabs-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .tab {
    transition: 0.2s ease background-color;
    border-radius: 8px;
    padding: 9px 12px;
    background-color: transparent;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    text-align: right;
    color: #000;

    &:not(:last-child) {
      margin-right: 20px;
    }

    &--counter {
      line-height: 14px;
      font-size: 12px;
      order: 0;
    }

    &:hover,
    &__active {
      background-color: #fff;
    }
  }
</style>
