<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ExpansionList',
  methods: {
    closeAll (panels) {
      panels.forEach((panel) => {
        panel.$data.isOpen = false
      })
    },
  },
  mounted () {
    const panels = this.$children.filter(child => child.$options.name === 'ExpansionPanel')
    panels.forEach(panel => {
      panel.$on('onToggle', event => {
        this.closeAll(panels)
        panel.$data.isOpen = event
      })
    })
  },
}
</script>
