<template>
  <label
    class="base-checkbox"
    :class="{'__left': leftText}"
  >
    {{label}}
    <input
      class="base-checkbox-input"
      type="checkbox"
      :checked="isChecked"
      :value="value"
      @change="updateInput"
    />
    <span class="base-checkbox-checkmark" >
      <svg-icon v-if="isChecked" icon-name="checkmark"/>
    </span>
  </label>
</template>

<script>

export default {
  name: 'BaseCheckbox',
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    'value': { type: String },
    'modelValue': { default: '' },
    'label': { type: String, required: true },
    'trueValue': { default: true },
    'falseValue': { default: false },
    'leftText': {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isChecked () {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value)
      }
      return this.modelValue === this.trueValue
    },
  },
  methods: {
    updateInput (event) {
      let isChecked = event.target.checked
      if (this.modelValue instanceof Array) {
        let newValue = [...this.modelValue]
        if (isChecked) {
          newValue.push(this.value)
        } else {
          newValue.splice(newValue.indexOf(this.value), 1)
        }
        this.$emit('change', newValue)
      } else {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue)
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.base-checkbox {
  display: block;
  position: relative;
  padding-left: 30px;
  line-height: 20px;
  height: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.__left {
    padding: 0 30px 0 0;

    .base-checkbox-checkmark {
      left: auto;
      right: 0;
    }
  }
}

.base-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.base-checkbox-checkmark {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid $color-line;
  transition: background-color, border-color 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border-color: $color-gray-icon;
  }

  svg {
    color: transparent;
    stroke: #fff;
  }
}

.base-checkbox input:checked ~ .base-checkbox-checkmark {
  background-color: $color-primary
}

.profile-account-selectors .base-checkbox {
  font-size: 14px;
  margin-right: 40px;
}
</style>
