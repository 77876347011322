<template>
  <div class="model-card-header">
    <div
      class="model-card-header__photo"
    >
      <ProductImage
        :url="image"/>
    </div>
    <div
      class="model-card-header__name"
    >
      <router-link
        :to="`/model/${id}`"
      >
        {{ name }}
      </router-link>
    </div>
    <div class="model-card-header__badge"></div>
    <div
      class="model-card-header__stock"
    >
      {{brand}}
    </div>
    <div class="model-card-header__price">
      {{ category }}
      <!--price-recommendation :value="priceRecommendation"/-->
      <!--price-recommendation :value="showUniquePrice"/-->
    </div>
    <div class="model-card-header__approval-status">
      <p>{{ $t(`models.${approvalStatus}`) || approvalStatus }}</p>
      <router-link
        class="model-card-header__link"
        v-if="editIsAvailable"
        :to="`/edit/model/${id}`"
      >
        {{ $t('editModel') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import {
  MODEL_APPROVAL_AWAITING,
  MODEL_APPROVAL_REJECTED
} from '@/helpers/consts/model-approval-statuses'
import ProductImage from '../../images/ProductImage'
import { IN_STOCK, OUT_OF_STOCK } from '../../../../helpers/consts/stock-states'
import { BAD_PRICE, GOOD_PRICE, UNIQUE_PRICE } from '../../../../helpers/consts/price-quality-statuses'
import PriceRecommendation from '../../icons/PriceRecommendation'

export default {
  name: 'ModelCardHeader',
  components: {
    PriceRecommendation,
    ProductImage,
  },
  props: {
    image: {
      default: '',
    },
    name: {
      default: '',
    },
    brand: {
      default: '',
    },
    category: {
      default: '',
    },
    approvalStatus: {
      default: '',
    },
    id: {
      type: Number,
    },
    isOwner: {
      type: Boolean,
    },
    products: {
      type: Array,
      default: () => [],
    },
    priceRecommendation: {
      type: String,
    },
  },
  computed: {
    editIsAvailable () {
      if (this.isOwner === false) return false
      switch (this.approvalStatus) {
        case MODEL_APPROVAL_AWAITING:
        case MODEL_APPROVAL_REJECTED:
          return true
        default:
          return false
      }
    },
    stockMessage () {
      const hasProductsWithStock = this.products.reduce((acc, product) => {
        if (product.stock > 0) return true
        return acc
      }, false)

      return this.$t(hasProductsWithStock ? IN_STOCK : OUT_OF_STOCK)
    },
    priceIconName () {
      switch (this.priceRecommendation) {
        case GOOD_PRICE:
          return 'good-price'
        case BAD_PRICE:
          return 'bad-price'
        default:
          return ''
      }
    },
    showUniquePrice () {
      return this?.products?.length === 1 ? UNIQUE_PRICE : null
    },
  },
}
</script>

<style lang="scss" scoped>
.model-card-header {
  padding: 16px;
  display: flex;
  width: 1200px;
  box-sizing: border-box;
  text-align: left;
  align-items: center;

  &__photo {
    width: $photo-width;
  }

  &__name {
    width: $variations-width;

    a {
      color: $text-black;
      font-size: 14px;
      line-height: 16px;
      text-decoration: underline dashed $text-black;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__badge {
    width: $sku-width;
  }

  &__stock {
    width: $stock-width;
  }

  &__price {
    width: $price-width;
  }

  &__approval-status {
    width: $approval-status-width;
  }

  &__link {
    color: $color-primary;
    font-size: 14px;
    line-height: 16px;
    text-decoration: underline dashed $color-primary;

    &:hover {
      text-decoration: none;
    }
  }

  &--icon {
    cursor: pointer;
    fill: none;
    position: absolute;
    top: calc(50% - 8px);
    right: 0;
  }
}
</style>
