<template>
  <div class="main-content">
    <base-bread-crumbs/>
    <div class="main-content__inner">
      <div class="page-title">{{ $t('productList') }}</div>

      <div class="list-page-header">
        <div class="goods-filters-panel">
          <BaseTabs
            v-if="statuses"
            custom-class="goods-filters-panel--tabs"
            :data="statuses"
            @onClick="changeActiveTab"
          />

          <div class="goods-filters-panel--status">
          <span class="goods-filters-panel--label-text">
            {{$t('productStatus')}}
          </span>
            <base-select
              :style="{ width: '118px'}"
              :value="selectedProductStatusObj"
              :options="productStatuses"
              :clearable="false"
              :searchable="false"
              @input="changeProductStatuses"
            />
          </div>

          <BaseCheckbox
            :model-value="expandAllModels"
            :label="$t('expandAllModels')"
            :leftText="true"
            @change="toggleModels"
          />

          <div class="goods-filters-panel--show-on-page">
          <span class="goods-filters-panel--label-text">
            {{$t('modelsOnPage')}}
          </span>
            <base-select
              :value="pageSize"
              :options="[20, 50, 100]"
              :clearable="false"
              @input="changePageSize"
            />
          </div>
        </div>

        <div class="goods-product-panel">
          <base-input
            :value="query"
            :placeholder="$t('searchProductPlaceholder')"
            containerClass="goods-product-panel--search"
            :fullWidth="true"
            iconName="search-icon"
            @input="searchProduct"
          />
          <!--simple-button
            :value="$t('batchUpdate')"
            :transparent="true"
            icon=""
            @click="batchUpdate"
          /-->
          <!--simple-button
            :value="$t('batchCreate')"
            :transparent="true"
            icon="plus"
            @click="batchCreate"
          /-->
          <simple-button
            icon="plus"
            :value="$t('addProduct')"
            :style="{ stroke: '#fff'}"
            @click="addProduct"
          />
        </div>
      </div>

      <modal-on-overlay
        v-if="isModalVisible"
        :footerEnabled="false"
        textAlign="center"
        @close="closeModal"
      >
        <template #header>
          {{ batchModalHeader }}
        </template>
        <template #body>
          <batch-products :type="batchType"/>
        </template>
      </modal-on-overlay>
    </div>

    <template v-if="models.length">
      <div class="model-cards-headers">
        <div class="model-cards-headers__photo">
          {{ $t('photo') }}
        </div>
        <div class="model-cards-headers__name">
          {{ $t('listName') }}
        </div>
        <div class="model-cards-headers__stock align-right">
          {{ $t('brand') }}
        </div>
        <div class="model-cards-headers__price align-right">
          {{ $t('category') }}
        </div>
        <div class="model-cards-headers__approval-status align-right">
          {{ $t('listStatus') }}
        </div>
      </div>

      <div class="models-cards-list">
        <ExpansionList>
          <BaseExpansionPanel
            v-for="model in models"
            :key="model.id"
            :open="expandAllModels"
            class="product-item"
          >
            <template #header>
              <ModelCardHeader
                :image="model.image"
                :name="model.name"
                :brand="model.brand ? model.brand.name : ''"
                :category="model.category ? model.category.name : ''"
                :approvalStatus="model.moderation"
                :isOwner="model.isOwner"
                :id="model.id"
                :products="model.products"
                :priceRecommendation="model.priceRecommendation"
              />
            </template>
            <template #content>
              <ModelCardBody
                :products="model.products"
                @updateProduct="updateProduct"
                @addProductToModel="() => { return addProductToModel(model.id) }"
              />
            </template>
          </BaseExpansionPanel>
        </ExpansionList>
      </div>
    </template>
    <div
      v-else
      class="message-panel"
    >
      <div class="message-panel--content">
        {{ $t('noItems') }}
      </div>
    </div>

    <div class="pagination-panel">
      <ListPagination
        :pagination="modelsPagination"
        @onSelectPage="changePage"
      />
      <div class="goods-filters-panel--show-on-page">
          <span class="goods-filters-panel--label-text">
            {{$t('modelsOnPage')}}
          </span>
        <base-select
          :value="pageSize"
          :options="[20, 50, 100]"
          :clearable="false"
          @input="changePageSize"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseBreadCrumbs from '@/components/ui/layout/BaseBreadCrumbs.vue'
import BaseTabs from '@/components/ui/layout/BaseTabs'
import BaseCheckbox from '@/components/ui/forms/BaseCheckbox'
import ordersStatuses from '@/helpers/consts/order-statuses'
import { mapState } from 'vuex'
import productStatuses from '@/helpers/consts/product-statuses'
import ListPagination from '@/components/ui/tables/pagination/ListPagination'
import ExpansionList from '@/components/ui/expansion/ExpansionList'
import BaseExpansionPanel from '@/components/ui/expansion/BaseExpansionPanel'
import ModelCardHeader from '@/components/ui/layout/models/ModelCardHeader'
import ModelCardBody from '@/components/ui/layout/models/ModelCardBody'
import debounce from 'lodash.debounce'
import BaseSelect from 'smarket-ui/src/components/select/BaseSelect'
import BaseInput from 'smarket-ui/src/components/forms/BaseInput'
import SimpleButton from 'smarket-ui/src/components/buttons/SimpleButton'
import ModalOnOverlay from '../ui/modals/ModalOnOverlay'
import BatchProducts from '../ui/BatchProducts'
import { BATCH_CREATE, BATCH_UPDATE } from '../../helpers/consts/batch-types'

export default {
  data () {
    return {
      activeStatus: 1,
      batchType: '',
      isModalVisible: false,
    }
  },

  name: 'Goods',
  components: {
    BatchProducts,
    ModalOnOverlay,
    ModelCardBody,
    ModelCardHeader,
    BaseExpansionPanel,
    ExpansionList,
    ListPagination,
    BaseCheckbox,
    BaseTabs,
    BaseBreadCrumbs,
    BaseSelect,
    SimpleButton,
    BaseInput,
  },

  computed: {
    ...mapState(
      {
        models: state => state.models.filteredModels,
        modelsStatuses: state => state.models.statuses,
        selectedProductStatus: state => state.models.productStatus,
        expandAllModels: state => state.models.expandAllModels,
        modelsPagination: state => state.models.pagination,
        pageSize: state => state.models.pagination.per_page,
        query: state => state.models.query,
      },
    ),

    statuses () {
      let allGoodsCount = 0
      let statuses = JSON.parse(JSON.stringify(this.modelsStatuses)).map(status => {
        status.isActive = status.status === this.activeStatus
        status.statusName = this.$t(status.status_name)

        allGoodsCount += status.count

        return status
      })

      statuses.unshift({
        status: 'all',
        status_name: this.$t('allStatuses'),
        isActive: this.activeStatus === 'all',
        count: allGoodsCount,
      })

      return statuses
    },

    selectedProductStatusObj () {
      return {
        isActive: true,
        label: this.$t(this.selectedProductStatus),
        status: this.selectedProductStatus,
      }
    },

    productStatuses () {
      return productStatuses.map(status => {
        return {
          isActive: status === this.selectedProductStatus,
          label: this.$t(status),
          status,
        }
      })
    },

    batchModalHeader () {
      return this.batchType === BATCH_UPDATE ? this.$t('batchUpdate') : this.$t('batchCreate')
    },
  },

  methods: {
    changeActiveTab (tab) {
      if (tab && tab.status.toString() === this.$route.params.status.toString()) return
      this.$router.push({
        name: 'goods',
        params: {
          status: tab.status,
        },
      })
    },

    changeProductStatuses ({ status }) {
      this.$store.dispatch('productStatusChanged', status)
    },

    toggleModels (value) {
      this.$store.dispatch('toggleModels', value)
    },

    async changePageSize (perPage) {
      await this.$store.commit('setModelsPagination', {
        page: 1,
        per_page: perPage,
      })
      await this.fetchModels()
    },

    searchProduct: debounce(async function (query) {
      await this.$store.dispatch('updateQuery', query)
      await this.$store.commit('setModelsPagination', {
        page: 1,
        per_page: this.pageSize,
      })
      await this.fetchModels()
    }, 1000),

    addProduct () {
      this.$router.push({ name: 'models.creating' });
    },

    addProductToModel (modelId) {
      this.$router.push(`/create/product/${modelId}#new`)
    },

    async updateProduct (payload) {
      try {
        await this.$store.dispatch('updateProduct', payload)
        this.$store.commit('showHintNotification', {
          description: this.$t('productSuccessfullyUpdated', { id: payload.id }),
        })
      } finally {
        await this.fetchModels()
      }
    },

    async changePage (pageIndex) {
      await this.$store.commit('setModelsPagination', {
        page: pageIndex,
        per_page: this.pageSize,
      })
      await this.fetchModels()
    },

    async fetchModels () {
      try {
        const status = this.$route.params.status
        const payload = {
          page: this.modelsPagination.page,
          perPage: this.modelsPagination.per_page,
        }
        if (this.query) payload.term = this.query
        if (status !== 'all') payload.status = status
        await this.$store.dispatch('getModels', payload)
      } catch (error) {
        throw error
      }
    },
    batchCreate () {
      this.$store.commit('showOverlay')
      this.batchType = BATCH_CREATE
    },
    batchUpdate () {
      this.$store.commit('showOverlay')
      this.batchType = BATCH_UPDATE
    },
    closeModal () {
      this.$store.commit('hideOverlay')
      this.batchType = ''
    },
  },

  async beforeRouteUpdate (to, from, next) {
    const initialStatus = Object.keys(ordersStatuses)[0]
    const selectedStatus = to.params.status === undefined ? initialStatus : to.params.status

    if (selectedStatus !== 'all') {
      this.activeStatus = Number(selectedStatus)
    } else {
      this.activeStatus = selectedStatus
    }

    this.$nextTick(async () => { await this.fetchModels() })

    next()
  },

  created () {
    const status = Number(this.$route.params.status)
    this.activeStatus = isNaN(status) ? this.$route.params.status : status
  },

  async mounted () {
    this.$store.commit('setLoadingStatus')
    await this.fetchModels()
    await this.$store.dispatch('getModelStatusesWithCount', {})
    this.$store.commit('setSuccessStatus')
  },

  beforeRouteLeave (to, from, next) {
    this.activeStatus = null
    next()
  },
}

</script>

<style lang="scss">
  .product-list-title {
    margin-bottom: 21px;
    text-align: left;
  }

  .goods-filters-panel {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $text-gray;
    font-size: 14px;
    width: 100%;
    margin-bottom: 20px;

    &--tabs {
      margin-right: 35px;
      flex: 0 1 auto;
    }

    &--status {
      display: flex;
      align-items: center;
      margin-right: 35px;
      flex: 0 1 auto;
    }

    .base-checkbox {
      flex: 0 1 auto;
    }

    &--show-on-page {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 1 0 auto;
    }

    &--label-text {
      margin-right: 8px;
    }
  }

  .goods-product-panel {
    display: flex;
    align-items: center;

    &--search {
      margin-right: 20px;
      background-color: #fff;

      svg {
        fill: $text-gray
      }
    }

    .button {
      flex: 1 0 auto;

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  .models-cards-list {
    margin-bottom: 60px;
  }

  .model-cards-headers {
    display: flex;
    font-size: 14px;
    font-weight: bold;
    padding: 0 16px;
    border: 1px solid #D4DADF;
    border-radius: 4px 4px 0 0;
    background-color: #F3F5F7;

    > div {
      padding: 12px 8px;
      box-sizing: border-box;
      text-align: left;

      &:not(:last-child) {
        border-right: 1px solid #D4DADF;
      }
    }

    &__photo {
      width: $photo-width;
    }

    &__name {
      width: $name-width;
    }

    &__stock {
      width: $stock-width;
    }

    &__price {
      width: $price-width;
    }

    &__approval-status {
      width: $approval-status-width;
    }
  }

  .pagination-panel {
    display: flex;
  }


.list-page-header {
  background-color: #F3F5F7;
  border-radius: 14px;
  padding: 12px;
}

  .product-item {
    border: solid #D4DADF;
    border-width: 0 1px 1px 1px;
  }
</style>
