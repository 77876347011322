<template>
  <div class="model-card-body">
    <div class="text-14-500">
      {{ `${$t('variations')} (${products.length})`}}
    </div>

    <ProductTable
      :data="products"
      @onChangeProduct="updateProduct"
    />

    <!--div class="button-wrap">
      <simple-button
        :value="$t('addProductToModel')"
        :transparent="true"
        icon="plus"
        @click="$emit('addProductToModel')"
      />
    </div-->
  </div>
</template>

<script>
import ProductTable from '../../product/ProductTable'
import SimpleButton from 'smarket-ui/src/components/buttons/SimpleButton'
export default {
  name: 'ModelCardBody',
  components: { SimpleButton, ProductTable },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    updateProduct (payload) {
      this.$emit('updateProduct', payload)
    },
  },
}
</script>

<style lang="scss" scoped>
  .model-card-body {
    background-color: #fff;
    padding: 8px 12px;
  }
</style>
