<template>
  <div class="batch-products">
    <template v-if="showSuccessfullyUpload">
      <template v-if="hasSuccessList">
        <success-icon/>
        <p class="has-success">
          {{$t('uploadedProductsCount', { count: uploadResult.successList.length})}}
        </p>
      </template>
      <template v-if="hasErrorList">
        <p class="has-errors">
          {{$t('uploadedWithErrorsProductsCount', { count: uploadResult.errorList.length})}}
        </p>
        <div class="errors-list">
          <div class="errors-list--item" v-for="error in uploadResult.errorList" :key="error.message">
            {{error.message}}
          </div>
        </div>
      </template>
    </template>
    <template v-else-if="showProgressBar === false">
      <a :href="downloadTemplateLink" class="download-link">
        <svg-icon icon-name="download"/> {{ $t('downloadTemplate') }}
      </a>
      <p class="download-link-description">{{ $t('downloadTemplateDescription') }}</p>
      <div class="batch-products--upload-container">
        <simple-button
          class="batch-products--upload-button"
          :value="$t('uploadXlsxButton')"
          icon="upload-button"
        />
        <input
          type="file"
          name="image"
          class="batch-products--upload-input"
          @drop.prevent="handleFileDrop"
          @change="handleFileInput"
        >
      </div>
      <p class="more">{{ $t('moreInfo') }}</p>
    </template>
    <template v-else>
      <sm-loader class="batch-loader"/>
      <div class="uploading">
        {{ $t('uploading')}}
      </div>
      <progress-bar :active="uploadInProgress" :failed="uploadError"/>
    </template>
  </div>
</template>

<script>
import SvgIcon from 'shared/src/components/SvgIcon'
import SimpleButton from 'smarket-ui/src/components/buttons/SimpleButton'
import ProgressBar from './ProgressBar'
import SuccessIcon from './SuccessIcon'
import batchTypes from '../../helpers/consts/batch-types'
import SmLoader from './SmLoader'

export default {
  name: 'BatchProducts',
  props: {
    type: {
      type: String,
      default: 'create',
      validator: function (value) {
        return batchTypes.includes(value)
      },
    },
  },
  data () {
    return {
      showSuccessfullyUpload: false,
      showProgressBar: false,
      uploadInProgress: false,
      uploadError: false,
      uploadResult: null,
    }
  },
  components: { SmLoader, SuccessIcon, ProgressBar, SimpleButton, SvgIcon },
  computed: {
    downloadTemplateLink () {
      const fileName = this.type === 'create' ? 'create_products_example' : 'stock_update_example'
      return `/files/stock-templates/${fileName}.xlsx`
    },
    hasSuccessList () {
      return !!this?.uploadResult?.successList?.length
    },
    hasErrorList () {
      return !!this?.uploadResult?.errorList?.length
    },
  },
  methods: {
    async upload (file) {
      try {
        this.uploadInProgress = true
        this.showProgressBar = true
        this.uploadResult = await this.$store.dispatch('uploadBatchFile', {
          file,
          type: this.type,
        })
          .then((response) => {
            if (!response) {
              this.uploadError = true
            } else {
              this.showSuccessfullyUpload = true
              return response.data
            }
          })
      } catch (error) {
        this.uploadError = true
        await this.$store.dispatch('showError', error)
      } finally {
        this.uploadInProgress = false
        setTimeout(() => {
          this.showProgressBar = false
          this.uploadError = false
        }, 300)
      }
    },
    showTypeError () {
      this.$store.commit('showHintNotification', {
        type: 'ERROR',
        title: this.$t('docTypeError'),
        description: this.$t('docTypeErrorDescription'),
      })
    },
    handleFileDrop (e) {
      let droppedFiles = e.dataTransfer.files
      if (!droppedFiles) return
      ([...droppedFiles]).forEach(file => {
        if (/\.(xlsx)$/i.test(file.name)) {
          this.upload(file)
        } else {
          this.showTypeError(file)
        }
      })
      this.hoverUpload = false
    },
    handleFileInput (e) {
      let filesArr = e.target.files
      if (!filesArr) return
      ([...filesArr]).forEach(file => {
        if (/\.(xlsx)$/i.test(file.name)) {
          this.upload(file)
        } else {
          this.showTypeError(file)
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.batch-products {
  text-align: center;

  &--upload-container {
    position: relative;
    cursor: pointer;
  }
  &--upload-input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
  }

  &--upload-button {
    margin-bottom: 24px;
  }
}
.download-link {
  text-decoration: none;
  display: inline-block;
  font-weight: bold;
  color: $color-primary;
  margin-bottom: 12px;
}
.download-link-description {
  color: $text-gray;
  margin-bottom: 24px;
}
.more {
  color: $text-gray;
  text-decoration: underline dashed;
}
.uploading {
  color: $text-gray;
  font-weight: 700;
  margin-bottom: 24px;
}
.batch-loader {
  margin-bottom: 24px;
}
.has-success {
  margin-bottom: 20px;
}
.has-errors {
  color: $color-error-secondary;
  margin-bottom: 16px;
}
.errors-list {
  border: 1px solid $color-line;
  padding: 20px;
  max-height: 230px;
  overflow: scroll;
  text-align: left;

  &--item {
    color: $color-error-secondary;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 12px;
  }
}
</style>
