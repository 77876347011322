<template>
  <div class="progress-bar">
    <div
      class="progress-bar--line"
      :class="{ '__failed': failed }"
      :style="{ width: lineWidth }"
    ></div>
  </div>
</template>

<script>
import progress from 'progress-it'

export default {
  name: 'progress-bar',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    failed: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      progress: 0,
      finished: false,
      stateSetter: null,
    }
  },
  computed: {
    lineWidth () {
      return `${this.progress * 100}%`
    },
  },
  methods: {
    effect (val) {
      this.progress = val
    },
  },
  watch: {
    active: {
      immediate: true,
      handler (active) {
        if (active) {
          this.stateSetter = progress(this.effect)(false)
        } else if (this.stateSetter) {
          this.stateSetter(true)
        }
      },
      failed (failed) {
        if (failed && this.stateSetter) {
          this.stateSetter('fail')
        }
      },
    },
  },
}
</script>

<style scoped lang="scss">
.progress-bar {
  height: 8px;
  background: $color-line;
  border-radius: 4px;
  overflow: hidden;

  &--line {
    height: 8px;
    border-radius: 4px;
    background: $color-primary;
    transition: 0.1s ease all;

    &.__failed {
      background-color: $color-error-secondary;
    }
  }
}
</style>
